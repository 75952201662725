<template>
    <v-card>
        <v-card-title :class="turnoColor+' lighten-3'">
            {{turnoCompleto}}
            <v-spacer></v-spacer>

            <v-btn
                class="mr-1"
                small
                color="primary"
                dark
                rounded
            >
                <v-icon small>mdi-silverware-fork-knife</v-icon>
            </v-btn>

            <v-btn
                class="ml-1"
                small
                color="green"
                dark
                rounded
                @click="dialogNut=true"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-progress-linear
                v-if="loadingNut"
                :active="loadingNut"
                :indeterminate="loadingNut"
                absolute
                top
                height='5'
                :color="turnoColor+' darken-5'"
            ></v-progress-linear>


        </v-card-title>
        <v-card-text>
            
            <v-alert type="info" dense text class="mt-2" border="left" v-if="!loadingNut && valoresNut.length==0">
                No hay registros
            </v-alert>
            <v-list three-line subheader>
                <transition-group name="list" tag="p">
                    <v-list-item v-for="item in valoresNut" :key="item.id">
                        <v-list-item-content >
                        
                            <v-list-item-title>
                                <v-icon
                                    small
                                    color="red "
                                    v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                                    @click="borraEntrada(item.id)">
                                        mdi-eraser
                                </v-icon>
                                {{item.hora}} {{item.tipoDieta}} {{item.medio}}</v-list-item-title>
                            <v-list-item-subtitle @click="muestraInfo(item)">
                                <v-row class="px-1" no-gutters>
                                    <v-col cols="6">      
                                        <b>Indicado:</b> {{item.cantidadIndicada}}
                                    </v-col>
                                    <v-col cols="6">
                                        <b>Tolerado: </b>{{item.tolera}}
                                    </v-col>
                                    
                                    <v-col cols="6" v-if="item.prePrandial || item.postPrandial">
                                        <b>Per. prepran.: </b>{{item.prePrandial}}
                                    </v-col>
                                    <v-col cols="6" v-if="item.prePrandial || item.postPrandial">
                                        <b>Per. postpran.: </b>{{item.postPrandial}}
                                    </v-col>
                                </v-row>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.observaciones">
                                {{item.observaciones}}
                            </v-list-item-subtitle>
                            <v-divider/>
                        </v-list-item-content>
                    </v-list-item>
                </transition-group>
            </v-list>
        </v-card-text>
        <nuevo-nut
            :dialogNut.sync="dialogNut"
            :internamiento="internamiento"
            :estaFecha='estaFecha'
            :turno='turno'
            @agregadoNut="cargaValores(turno)"
        />
    </v-card>
</template>

<script>
import NuevoNut from '@/components/hospital/enfe/nutricion/NutricionNuevo'
export default {
    components:{
        NuevoNut,
    },
    mounted(){
        this.cargaValores(this.turno)
    },
    props:{
        turno: String,
        estaFecha: String,
        internamiento: Number,
        
    },
    data:()=>({
        dialogNut:false,
        valoresNut:[],
        loadingNut:false,
    }),
    methods:{
        async cargaValores(val){
            this.loadingNut= true
            let params={
                fecha:this.estaFecha,
                internamiento:this.internamiento,
                turno:val,
            }
            try{
                let cn = await this.$http({
                    method:'GET',
                    url:'/enfermeria/listNutricion',
                    params:params
                })
                this.loadingNut= false
                this.valoresNut = cn.data.root
                
            } catch(err){
                this.loadingNut= false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        muestraInfo(itm){
            this.$swal.fire({
                title: 'Detalles de la entrada',
                text: 'Registrado por '+ itm.creado +' el '+itm.dateCreated,
                confirmButtonText: 'Ok',
            })
        },
        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },

        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraNutricion',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    this.cargaValores()
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borraEntrada(id){
            //let motivo 
            this.$swal.fire({
                title: 'Borrar esta entrada',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {

                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },

    },
    computed:{
        turnoCompleto(){
            let text = ''

            switch (this.turno){
                case 'mat':
                    text = 'Matutino'
                    break
                case 'ves':
                    text = "Vespertino"
                    break
                case 'noc':
                    text = 'Nocturno'
                    break
            }

            return text
        },
        turnoColor(){
            let text = ''

            switch (this.turno){
                case 'mat':
                    text = 'blue'
                    break
                case 'ves':
                    text = "green"
                    break
                case 'noc':
                    text = 'red'
                    break
            }
            return text
        },
    },
    watch:{
        estaFecha(){
            this.cargaValores(this.turno)
        }
    },
}
</script>

<style>
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>