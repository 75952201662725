<template>
    <v-dialog
        v-model="dialog"
        max-width="450"
        persistent
    >
        <v-card>
            <v-form
                ref="nutForm"
                @submit="agrega"
                onSubmit="return false;"
                v-model="validaNut"
            >
                <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                >
                    Nueva entrada de nutricion
                </v-card-title>

                <v-card-text>

                    <v-row class="text-center pt-2" no-gutters> 
                        <v-col class="px-1" cols="12">
                            <v-text-field
                                v-model="datosNut.tipoDieta"
                                :rules="[
                                    value => !!value || 'Required.',
                                ]"
                                label="Tipo de dieta"
                                rounded
                                outlined
                                dense
                            ></v-text-field>

                        </v-col>
                        <v-col class="px-1" cols="6">
                            
                            <v-select
                                :rules="[
                                    value => !!value || 'Required.',
                                ]"
                                v-model="datosNut.medio"
                                :items="medioItems"
                                label="Medio"
                                rounded
                                outlined
                                dense
                            ></v-select>

                        </v-col>
                        <v-col class="px-1" cols="6">
                            <v-select
                                :rules="[
                                    value => !!value || 'Required.',
                                    ]"
                                v-model="datosNut.hora"
                                :items="horaList"
                                label="Hora"
                                rounded
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col class="px-1" cols="6">
                            <v-text-field
                                v-model="datosNut.cantidadIndicada"
                                rounded
                                outlined
                                :rules="[
                                    value => value >= 0 || 'Valor tiene que ser mayor a 0',
                                    value => !!value || 'Required.',
                                ]"
                                type="number"
                                label="Cantidad indicada"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" cols="6">
                            <v-text-field
                                v-model="datosNut.tolera"
                                rounded
                                outlined
                                :rules="[
                                    value => value >= 0 || 'Valor tiene que ser mayor a 0',
                                    value => !!value || 'Required.',
                                ]"
                                type="number"
                                label="Tolerancia"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" cols="6">
                            <v-text-field
                                v-model="datosNut.prePrandial"
                                rounded
                                outlined
                                label="Perímetro preprandial"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" cols="6">
                            <v-text-field
                                v-model="datosNut.postPrandial"
                                rounded
                                outlined
                                label="Perímetro postprandial"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1" cols="12">
                            <v-text-field
                                v-model="datosNut.observaciones"
                                label="Observaciones"
                                rounded
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        color="success"
                        type="submit"
                        :disabled="!validaNut"
                        :loading="loadingNut"
                    >
                        Agregar
                    </v-btn>

                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogNut:Boolean,
        internamiento:Number,
        estaFecha:String,
        turno:String,
    },
    data:()=>({
        validaNut:false,
        loadingNut:false,

        medioItems:[
            'Succ', 'SOG', 'LM','Ente', 'Parente', 'Ayuno'
        ],

        medioItemsCambio:{
            'Succ':1,
            'SOG':2,
            'LM':1,
            'Ente':1,
            'Parente':5,
        },
        
        datosNut:{
            medio:'',
            hora:'',
            cantidadInidicada:null,
            tolera:null,
            prePrandial:'',
            postPrandial:'',
            tipoDieta:'',
            observaciones:'',
        },
    }),
    methods:{
        
        async agrega(){
            //console.log('agrega')
            this.loadingNut = true
            let params = {
                ...this.datosNut,
                internamiento:this.internamiento,
                turno:this.turno,
                fecha: this.estaFecha
            }

            try{
                let nu = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaNutricion',
                    data:params
                })

                this.loadingNut = false

                if (nu.data.estado == true){
                    this.$emit('agregadoNut')
                    
                    this.dialog = false


                    //console.log(this.datosNut.medio)

                    let prms = {

                        internamiento:this.internamiento,

                        turno: this.turno,
                        fecha:this.estaFecha,
                        hora:this.datosNut.hora,

                        item:this.medioItemsCambio[this.datosNut.medio],

                        observaciones:this.datosNut.tipoDieta,
                        cantidad:this.datosNut.tolera,
                    }

                    //console.log(prms)

                    

                    try{
                        let sal = await this.$http({
                            method:'POST',
                            url: '/enfermeria/guardaInOut',
                            data: prms
                        })
                        if(sal.data.estado == true){
                            this.$refs.nutForm.reset()
                        } else {
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'No a balance',
                                text: 'No se puede agregar a balance. Verifique los datos e intente de nuevo',
                            })
                        }



                    }catch(err){
                        this.$store.dispatch('security/revisaError',err)
                    }
                    

                } else {
                    console.log(nu.data.root)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar REGISTRO DE ATENCIÓN',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            } catch(err){
                this.loadingNut = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        cancela(){
            console.log('cancela')
            this.$refs.nutForm.reset()
            this.dialog = false
        },
    },

    computed:{
        dialog:{
            get(){
                return this.dialogNut
            },
            set(val){
                //console.log(val)
                this.$emit('update:dialogNut',val)
            },
        },
        horaList(){
            let hl=[]
            switch (this.turno){
                case 'mat':
                    hl = ['08:00', '09:00','10:00','11:00','12:00','13:00','14:00']
                    break
                case 'ves':
                    hl = ['15:00','16:00','17:00','18:00','19:00','20:00']
                    break
                case 'noc':
                    hl = ['21:00','22:00','23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00',]
                    break

                }
            return hl

        },
    },
}
</script>

<style>

</style>