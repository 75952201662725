<template>
    <div>
        <v-toolbar short dense color="primary" dark flat>
            <v-toolbar-title>
                Monitorización nutrimental y líquidos via oral
            </v-toolbar-title>
            
            <v-progress-linear
                v-if="loadingDatos"
                :active="loadingDatos"
                :indeterminate="loadingDatos"
                absolute
                bottom
                color="green"
            ></v-progress-linear>

            <v-spacer></v-spacer>
            
            <v-btn icon @click="cargaNutTodos">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            
        </v-toolbar>
        <v-row class="px-1" v-show="muestraNut">
            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <nutricion-turno
                    :internamiento="internamiento"
                    :estaFecha="estaFecha"
                    turno="mat"
                />
            </v-col>
            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <nutricion-turno
                    :internamiento="internamiento"
                    :estaFecha="estaFecha"
                    turno="ves"
                />
            </v-col>
            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <nutricion-turno
                    :internamiento="internamiento"
                    :estaFecha="estaFecha"
                    turno="noc"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import NutricionTurno from '@/components/hospital/enfe/nutricion/NutricionTurno'
export default {
    components:{
        NutricionTurno
    },
    mounted(){
        this.cargaNutTodos()
    },
    props:{
        internamiento:Number,
        estaFecha:String,
    },
    data:()=>({
        loadingDatos:false,
        muestraNut:true,
    }),
    methods:{
        cargaNutTodos(){
            //console.log('cargando NUTRICION')
        },
    },
    watch:{
        estaFecha(){
            this.cargaNutTodos()
        },
    }
}
</script>

<style>

</style>