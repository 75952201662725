var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"450","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"nutForm",attrs:{"onSubmit":"return false;"},on:{"submit":_vm.agrega},model:{value:(_vm.validaNut),callback:function ($$v) {_vm.validaNut=$$v},expression:"validaNut"}},[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" Nueva entrada de nutricion ")]),_c('v-card-text',[_c('v-row',{staticClass:"text-center pt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
                                value => !!value || 'Required.',
                            ],"label":"Tipo de dieta","rounded":"","outlined":"","dense":""},model:{value:(_vm.datosNut.tipoDieta),callback:function ($$v) {_vm.$set(_vm.datosNut, "tipoDieta", $$v)},expression:"datosNut.tipoDieta"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"rules":[
                                value => !!value || 'Required.',
                            ],"items":_vm.medioItems,"label":"Medio","rounded":"","outlined":"","dense":""},model:{value:(_vm.datosNut.medio),callback:function ($$v) {_vm.$set(_vm.datosNut, "medio", $$v)},expression:"datosNut.medio"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"rules":[
                                value => !!value || 'Required.',
                                ],"items":_vm.horaList,"label":"Hora","rounded":"","outlined":"","dense":""},model:{value:(_vm.datosNut.hora),callback:function ($$v) {_vm.$set(_vm.datosNut, "hora", $$v)},expression:"datosNut.hora"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","rules":[
                                value => value >= 0 || 'Valor tiene que ser mayor a 0',
                                value => !!value || 'Required.',
                            ],"type":"number","label":"Cantidad indicada","dense":""},model:{value:(_vm.datosNut.cantidadIndicada),callback:function ($$v) {_vm.$set(_vm.datosNut, "cantidadIndicada", $$v)},expression:"datosNut.cantidadIndicada"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","rules":[
                                value => value >= 0 || 'Valor tiene que ser mayor a 0',
                                value => !!value || 'Required.',
                            ],"type":"number","label":"Tolerancia","dense":""},model:{value:(_vm.datosNut.tolera),callback:function ($$v) {_vm.$set(_vm.datosNut, "tolera", $$v)},expression:"datosNut.tolera"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"Perímetro preprandial","dense":""},model:{value:(_vm.datosNut.prePrandial),callback:function ($$v) {_vm.$set(_vm.datosNut, "prePrandial", $$v)},expression:"datosNut.prePrandial"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"Perímetro postprandial","dense":""},model:{value:(_vm.datosNut.postPrandial),callback:function ($$v) {_vm.$set(_vm.datosNut, "postPrandial", $$v)},expression:"datosNut.postPrandial"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Observaciones","rounded":"","outlined":"","dense":""},model:{value:(_vm.datosNut.observaciones),callback:function ($$v) {_vm.$set(_vm.datosNut, "observaciones", $$v)},expression:"datosNut.observaciones"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","type":"submit","disabled":!_vm.validaNut,"loading":_vm.loadingNut}},[_vm._v(" Agregar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancela}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }